/**
 * Google map
 */
function initMap() {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 14,
        scrollwheel: false,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(45.165826, 6.429703), // 44 rue Boson, 38200 Vienne

        // How you would like to style the map. 
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#b4d4e1"},{"visibility":"on"}]}]
    };

    // Get the HTML DOM element that will contain your map 
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    var image = new google.maps.MarkerImage('/images/pointer@2x.png', null, null, null, new google.maps.Size(25,35));

    // Let's also add a marker while we're at it
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(45.165826, 6.429703),
        map: map,
        icon: image
    });
}


/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

$(document).ready(function() {

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Toggle menu
	 */
	$(".nav-toggle").click(function(e){
		e.preventDefault();
		$(".navigation").toggleClass("open");
		$(".lines-button").toggleClass("close");
	});

	/**
	 * Header style
	 */
	$('body').waypoint(function(direction){
		if (direction === 'down') {
			$('html').removeClass('ontop');
		} else {
			$('html').addClass('ontop');
		}
	}, {offset: '-'+'50'+'px' });

	/**
	 * Google Map : init
	 */
	if ($('#map').length > 0) {
		initMap();
	}


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/
	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#fcontact").submit(function(){
		if($("#fcontact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "contact/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});
});
